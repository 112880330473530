import React from 'react';
import { Formik, Form, Field } from 'formik';
import { ReactComponent as User } from "resources/svg/user.svg";
import { QRCodeCanvas } from 'qrcode.react';
import Logo from "resources/images/controlhr_dark.png";

const PhotoCapture = ({ setPhoto, hidePhotoCapture }) => {

  const handleCapture = () => {

    const video = document.getElementById("capture_video")
    if (!video) return;

    const canvas = document.createElement("canvas")
    canvas.style.display = "none"
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    const context = canvas.getContext("2d")

    document.body.appendChild(canvas)

    context.drawImage(video, 0, 0, canvas.width, canvas.height)

    const data = canvas.toDataURL("image/png")
    setPhoto(data)

    video.remove()
    canvas.remove()

    hidePhotoCapture()

  }

  navigator.mediaDevices.getUserMedia({
    video: {
      width: { min: 640, ideal: 1280, max: 1920 },
      height: { min: 480, ideal: 720, max: 1080 },
      facingMode: "environment",
      frameRate: { ideal: 30, max: 60 }
    }
  }).then((stream) => {

    const video = document.getElementById("capture_video")
    if (!video) return;

    video.srcObject = stream

    video.addEventListener("loadedmetadata", () => {
      video.play()
    })

  })

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ width: "100%", position: "absolute", zIndex: 9999, top: "0", left: "0", backgroundColor: "#fff", overflowY: "hidden" }}>

      <video id="capture_video" style={{ maxHeight: "100%", maxWidth: "100%" }} />

      <button
        type='button'
        className="btn btn-danger w-50"
        style={{ position: "absolute", bottom: "70px" }}
        onClick={hidePhotoCapture}
      >
        Cancelar
      </button>

      <button
        type='button'
        className="btn btn-primary w-50"
        style={{ position: "absolute", bottom: "20px" }}
        onClick={handleCapture}
      >
        Tomar foto
      </button>

    </div>
  );

}

function FormView({
  theme = "light",
  sent,
  departments,
  brands,
  subBrands,
  colors,
  documentTypes,
  formikRef,
  onGenerateNoCard,
  onSubmit,
}) {
  if (sent) return (
    <div className='container w-100 h-100 p-5'>
      <div className='d-flex justify-content-center align-items-center w-100 h-100'>
        <h1 className='text-success'>¡Registro exitoso!</h1>
      </div>
    </div>
  )
  return (
    <div className='container'>
      <div className='row d-flex justify-content-center py-3 pt-6' style={{ overflowX: "hidden" }}>
        <div className='col-12 col-lg-10 col-xl-8 px-5' style={{ overflowX: "hidden" }}>
          
          <div className='mt-3' style={{ height: "100px" }}>
            <img src={Logo} alt="Logo" style={{ height: "65px" }} />
          </div>

          <Formik
            innerRef={formikRef}
            initialValues={{}}
            onSubmit={onSubmit}>
            {({ values, setFieldValue, resetForm }) =>
              <Form>

                <div className='d-flex w-100 justify-content-center'>

                  {
                    values.show_photo_capture &&
                    <PhotoCapture
                      setPhoto={(photo) => setFieldValue("photo", photo)}
                      hidePhotoCapture={() => setFieldValue("show_photo_capture", false)}
                    />
                  }

                  <div
                    className='form-group d-flex justify-content-center'
                    onClick={() => {
                      setFieldValue("show_photo_capture", true)
                    }}
                  >
                    {
                      values.photo
                        ? <>
                          <div className='d-flex justify-content-center align-items-center mb-3' style={{ width: "175px", height: "175px", borderRadius: "50%", overflow: "hidden" }}>
                            <img crossorigin="anonymous" src={values.photo} alt="Foto" style={{ width: "175px", height: "175px", cursor: "pointer" }} />
                          </div>
                        </>
                        : <User style={{ width: "175px", height: "175px", cursor: "pointer" }} />
                    }
                  </div>

                </div>

                <div className='row' style={{ overflowX: "hidden" }}>

                  <div className='col-md-6 form-group my-3'>
                    <label class="input">
                      <Field type="text" name="name" class="input__field form-control" placeholder=" " value={values.name || ''} required />
                      <span class="input__label">
                        Nombre
                        <span className='text-danger fw-bold'>*</span>
                      </span>
                    </label>
                  </div>

                  <div className='col-md-6 form-group my-3'>
                    <label class="input">
                      <Field as="select" name="genre" class="input__field form-control" required value={values.genre || ''}>
                        <option value="">Seleccione una opción</option>
                        <option value="MALE">Masculino</option>
                        <option value="FEMALE">Femenino</option>
                      </Field>
                      <span class="input__label">
                        Género
                        <span className='text-danger fw-bold'>*</span>
                      </span>
                    </label>
                  </div>

                  <div className='col-md-6 form-group my-3'>
                    <label class="input">
                      <Field type="text" name="host" class="input__field form-control" placeholder=" " value={values.host || ''} required />
                      <span class="input__label">
                        A quién visita
                        <span className='text-danger fw-bold'>*</span>
                      </span>
                    </label>
                  </div>

                  <div className='col-md-6 form-group my-3'>
                    <label class="input">
                      <Field as="select" name="department_id" class="input__field form-control" required value={values.department_id || ''}>
                        <option value="">Seleccione una opción</option>
                        {
                          departments.map((department) =>
                            <option value={department.id}>{department.description}</option>
                          )
                        }
                      </Field>
                      <span class="input__label">
                        Departamento
                        <span className='text-danger fw-bold'>*</span>
                      </span>
                    </label>
                  </div>

                  <div className='col-md-6 form-group my-3'>
                    <label class="input">
                      <Field as="select" name="document_type_id" class="input__field form-control" required value={values.document_type_id || ''}>
                        <option value="">Seleccione una opción</option>
                        {
                          documentTypes.map((documentType) =>
                            <option value={documentType.id}>{documentType.description}</option>
                          )
                        }
                      </Field>
                      <span class="input__label">
                        Documento de autenticación
                        <span className='text-danger fw-bold'>*</span>
                      </span>
                    </label>
                  </div>

                  <div className='col-md-6 form-group my-3'>
                    <label class="input">
                      <Field type="text" name="phone" class="input__field form-control" placeholder=" " value={values.phone || ''} required />
                      <span class="input__label">
                        Teléfono
                        <span className='text-danger fw-bold'>*</span>
                      </span>
                    </label>
                  </div>

                  <div className='my-3 d-flex w-100'>
                    <span className='ms-2'>
                      <label for="status-switch">Automóvil:</label>
                    </span>
                    <div className="form-check form-switch ms-2" id="status">
                      <Field
                        class="form-check-input"
                        type="checkbox"
                        id="status-switch"
                        onChange={(e) => setFieldValue("has_car", e.target.checked)}
                        checked={values.has_car || false}
                      />
                    </div>
                  </div>

                  {
                    values.has_car && <>

                      <div className='col-md-6 form-group my-3'>
                        <label class="input">
                          <Field as="select" name="brand_id" class="input__field form-control" required value={values.brand_id || ''}>
                            <option value="">Seleccione una opción</option>
                            {
                              brands.map((brand) =>
                                <option value={brand.id}>{brand.description}</option>
                              )
                            }
                          </Field>
                          <span class="input__label">
                            Marca
                            <span className='text-danger fw-bold'>*</span>
                          </span>
                        </label>
                      </div>

                      <div className='col-md-6 form-group my-3'>
                        <label class="input">
                          <Field as="select" name="sub_brand_id" class="input__field form-control" required value={values.sub_brand_id || ''}>
                            <option value="">Seleccione una opción</option>
                            {
                              subBrands.map((subBrand) =>
                                <option value={subBrand.id}>{subBrand.description}</option>
                              )
                            }
                          </Field>
                          <span class="input__label">
                            Submarca
                            <span className='text-danger fw-bold'>*</span>
                          </span>
                        </label>
                      </div>

                      <div className='col-md-6 form-group my-3'>
                        <label class="input">
                          <Field as="select" name="color_id" class="input__field form-control" required value={values.color_id || ''}>
                            <option value="">Seleccione una opción</option>
                            {
                              colors.map((color) =>
                                <option value={color.id}>{color.description}</option>
                              )
                            }
                          </Field>
                          <span class="input__label">
                            Color
                            <span className='text-danger fw-bold'>*</span>
                          </span>
                        </label>
                      </div>

                      <div className='col-md-6 form-group my-3'>
                        <label class="input">
                          <Field type="text" name="plates" class="input__field form-control" placeholder=" " value={values.plates || ''} required />
                          <span class="input__label">
                            Placas
                            <span className='text-danger fw-bold'>*</span>
                          </span>
                        </label>
                      </div>

                    </>
                  }

                  <div className='d-flex flex-column align-items-center justify-content-center w-100'>
                    <button
                      className='btn btn-success my-3 w-100'
                      onClick={onGenerateNoCard}
                      type='button'
                    >
                      Generar QR
                    </button>
                    {
                      values.no_card &&
                      <>
                        <div className='d-flex justify-content-center'>
                          <QRCodeCanvas
                            value={values.no_card}
                            size={200}
                            style={{ marginLeft: "20px" }}
                            onClick={() => {
                              const canvas = document.querySelector('canvas');
                              const dataURL = canvas.toDataURL('image/png');
                              const newTab = window.open('about:blank', 'image from canvas');
                              newTab.document.write("<img src='" + dataURL + "' alt='from canvas'/>");
                            }}
                          />
                        </div>
                        <div className='d-flex justify-content-center my-3 w-100'>
                          <button
                            type='button'
                            className='btn btn-warning ms-2 w-100'
                            onClick={() => {
                              const canvas = document.querySelector('canvas');
                              const dataURL = canvas.toDataURL('image/png');
                              const a = document.createElement('a');
                              a.href = dataURL;
                              a.download = 'QR.png';
                              a.click();
                            }}
                          >
                            Descargar
                          </button>
                        </div>
                      </>
                    }
                  </div>

                  <input type="hidden" name="no_card" value={values.no_card || ''} />

                  <div className='d-flex justify-content-between'>
                    <button
                      type='button'
                      className='btn btn-danger me-2 w-50'
                      onClick={() => {
                        formikRef.current.resetForm()
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      type='submit'
                      className='btn btn-primary ms-2 w-50'
                    >
                      Enviar
                    </button>
                  </div>

                </div>

              </Form>
            }</Formik>

        </div>
      </div>
    </div>
  )
}

export default FormView;