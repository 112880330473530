import React, { useRef, useState, useEffect } from "react";
import { usePost } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/geoZones/Form.view";
import { Rectangle, useMap, useMapEvent } from "react-leaflet"

function FormSave({ setIsVisible }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(() => { });
    const [coordinates, setCoordinates] = useState({})
    const [locations, setLocations] = useState([])
    const [isSelecting, setIsSelecting] = useState(false)

    const [callSave, reqSave] = usePost("/geo_zones", {
        onCompleted: () => {
            if (!toast.isActive("geozones-created"))
                toast.success("Geocerca creado correctamente", { toastId: "geozones-created" });

            setIsVisible(false);
            reset();
        },
        onError: (error) => {
            console.error(error)
            showResponseMessage(error.status, "geozones", "geocercas", "create")
        }
    })

    const [callEncode, reqEncode] = usePost("/geolocation/encode", {
        onCompleted: (data) => {
            setLocations(data.data)
            if(data.data.length === 1) {
                setCoordinates(data.data[0].geometry.location)
            }
        },
        onError: (err) => {}
    })
    
    const callGetCoordinates = async (address) => callEncode({ address })

    const RecenterAutomatically = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => { 
            if(isSelecting) return;
            map.setView([lat, lng]); 
        }, [lat, lng, isSelecting]);
        return null;
    }

    const MapSelect = ({ name, setFieldValue, values }) => {

        const map = useMap();
        if(isSelecting) map.dragging.disable();

        useMapEvent({
            mousemove: (e) => {
                if(!isSelecting) return;
                setFieldValue('xf', e.latlng.lat);
                setFieldValue('yf', e.latlng.lng);
            },
            mousedown: (e) => {
                if(!isSelecting) return;
                setFieldValue('xi', e.latlng.lat);
                setFieldValue('yi', e.latlng.lng);
            },
            mouseup: (e) => {
                map.dragging.enable();
                setIsSelecting(false);
                if(!isSelecting) return;
                setFieldValue('xf', e.latlng.lat);
                setFieldValue('yf', e.latlng.lng);
            },
        });

        return <>
            {
                (values.xi && values.yi && values.xf && values.yf) &&
                    <Rectangle bounds={[[values.xi, values.yi], [values.xf, values.yf]]} />
            }
        </>;

    }

    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        setReset(() => () => resetForm({}));
        callSave(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            coordinates={coordinates}
            locations={locations}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            setIsVisible={setIsVisible}
            setError={setError}
            setCoordinates={setCoordinates}
            callGetCoordinates={callGetCoordinates}
            isSelecting={isSelecting}
            setIsSelecting={setIsSelecting}
            RecenterAutomatically={RecenterAutomatically}
            MapSelect={MapSelect}
        />
    )
    
}

export default FormSave;