import React, { useEffect, useState, useRef } from "react";
import View from "components/crh/reports/incidencesReadOnly/AttendanceLogs.view";
import Loading from "helpers/Loading";
import { useGetCall, fetchPost } from "utils/api";

import { getParameters } from "api/companies";


function AttendanceLogs({
  closeModal,
  date,
  employeeKey,
  isVisible
}) {

  const [parameters, setParameters] = useState({});
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [callAttendanceLogs, reqAttendanceLogs] = useGetCall("/attendance_logs", {
    onCompleted: (response) => {
      setLoading(false);
      setAttendanceLogs(response.data.attendanceLogs);
    },
    onError: (error) => {
      setLoading(false);
    }
  });

  useEffect(() => {
    getParameters().then((data) => {
      setParameters(data.data);
    })
  }, []);

  useEffect(() => {
    setLoading(true);
    callAttendanceLogs({ 
      start_date: date, 
      end_date: date, 
      attendance_type: "BOTH",
      key: employeeKey, 
      registered: true 
    });
  }, [date, employeeKey, isVisible]);

  if(loading) return <Loading />

  return (
    <View 
      closeModal={closeModal}
      parameters={parameters}
      attendanceLogs={attendanceLogs} 
    />
  );
}

export default AttendanceLogs;