import React, { useState, useEffect, useRef } from "react";
import { usePut, useGetCall, usePost } from "utils/api";
import { toast } from "react-toastify";
import { showResponseMessage } from "utils/message";
import View from "components/crh/geoZones/Form.view";
import { Rectangle, useMap, useMapEvent } from "react-leaflet"

function FormSet({ id, setIsVisible, refresh }) {

    const formikRef = useRef();
    const [error, setError] = useState(null);
    const [coordinates, setCoordinates] = useState({})
    const [locations, setLocations] = useState([])
    const [geoZone, setGeoZone] = useState({});
    const [isSelecting, setIsSelecting] = useState(false)
    const [reset, setReset] = useState(() => { });
    const [refreshValue, setRefreshValue] = useState(null);

    if (refresh !== refreshValue) {
        setRefreshValue(refresh);
    }

    const [callGeoZone, reqBoss] = useGetCall(`/geo_zones/${id}`, {
        onCompleted: (response) => {
            if (formikRef.current) {
                formikRef.current.setValues(response.data);
            }
            setGeoZone(response.data);
        }, onError: (error) => {
            showResponseMessage(error.status, "geozones", "geocercas", "read")
        }
    })

    const [callUpdate, reqUpdate] = usePut("/geo_zones", {
        onCompleted: () => {
            if (!toast.isActive("geozones-updated"))
                toast.success("geocerca actualizada", { toastId: "geozones-updated" });

            setIsVisible(false);
            reset();
        },
        onError: (err) => {
            console.error(err)
            showResponseMessage(error.status, "geozones", "geocercas", "edit")
        }
    })

    const [callEncode, reqEncode] = usePost("/geolocation/encode", {
        onCompleted: (data) => {
            setLocations(data.data)
            if(data.data.length === 1) {
                setCoordinates(data.data[0].geometry.location)
            }
        },
        onError: (err) => {}
    })
    
    const callGetCoordinates = async (address) => callEncode({ address })

    const RecenterAutomatically = ({ lat, lng }) => {
        const map = useMap();
        useEffect(() => { map.setView([lat, lng]); }, [lat, lng]);
        return null;
    }

    const MapSelect = ({ name, setFieldValue, values }) => {

        const map = useMap();
        if(isSelecting) map.dragging.disable();

        useMapEvent({
            mousemove: (e) => {
                if(!isSelecting) return;
                setFieldValue('xf', e.latlng.lat);
                setFieldValue('yf', e.latlng.lng);
            },
            mousedown: (e) => {
                if(!isSelecting) return;
                setFieldValue('xi', e.latlng.lat);
                setFieldValue('yi', e.latlng.lng);
            },
            mouseup: (e) => {
                map.dragging.enable();
                setIsSelecting(false);
                if(!isSelecting) return;
                setFieldValue('xf', e.latlng.lat);
                setFieldValue('yf', e.latlng.lng);
            },
        });

        return <>
            {
                (values.xi && values.yi && values.xf && values.yf) &&
                    <Rectangle bounds={[[values.xi, values.yi], [values.xf, values.yf]]} />
            }
        </>;
        
    }

    useEffect(() => {
        callGeoZone();
        formikRef.current.setValues({});
    }, [id, refreshValue])


    const onSubmit = (values, { resetForm }) => {

        for (let key in values)
            if (values[key] === "")
                values[key] = null

        const { key, description, abbreviation } = values
        if (!key || !description || !abbreviation) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        values.id = id;
        setReset(() => () => resetForm({}));
        callUpdate(values);

    }

    const onCancel = (resetForm) => {
        setIsVisible(false);
        resetForm({});
    }

    return (
        <View
            formikRef={formikRef}
            coordinates={coordinates}
            locations={locations}
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
            edit={true}
            geoZone={geoZone}
            setIsVisible={setIsVisible}
            setError={setError}
            setCoordinates={setCoordinates}
            callGetCoordinates={callGetCoordinates}
            isSelecting={isSelecting}
            setIsSelecting={setIsSelecting}
            RecenterAutomatically={RecenterAutomatically}
            MapSelect={MapSelect}
        />
    )


}

export default FormSet;
