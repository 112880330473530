import React, { useState, useEffect } from "react";
import moment from "moment";
import { dataURItoBlob } from "utils/files";

const RealTimeClock = () => {

	const [date, setDate] = useState(moment());

	useEffect(() => {
		setInterval(() => {
			setDate(moment());
		}, 1000);
	}, [])

	return (
		<div className="text-center">
			<h3>Fecha: {date.format("DD-MM-YYYY")}</h3>
			<h3>Hora: {date.format("HH:mm:ss")}</h3>
		</div>
	);

}

const PhotoCapture = ({ setEvent, event, setShowPhotoCapture }) => {

	const handleCapture = () => {
		
		const video = document.getElementById("capture_video")
		if(!video) return;

		const canvas = document.createElement("canvas")
		canvas.style.display = "none"
		canvas.width = video.videoWidth
		canvas.height = video.videoHeight

		const context = canvas.getContext("2d")

		document.body.appendChild(canvas)

		context.drawImage(video, 0, 0, canvas.width, canvas.height)

		const data = canvas.toDataURL("image/png")
		const blob = dataURItoBlob(data)

		setEvent({
			type: event.type,
			photo: blob
		})

		video.remove()
		canvas.remove()

		setShowPhotoCapture(false)

	}

	navigator.mediaDevices.getUserMedia({ video: {
		width: { min: 640, ideal: 1280, max: 1920 },
		height: { min: 480, ideal: 720, max: 1080 },
		facingMode: "environment",
		frameRate: { ideal: 30, max: 60 }
	}}).then((stream) => {

		const video = document.getElementById("capture_video")
		if(!video) return;

        video.srcObject = stream

        video.addEventListener("loadedmetadata", () => {
          video.play()
        })

	})

	return (
		<div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ width: "100%", position: "absolute", zIndex: 9999, top: "0", left: "0", backgroundColor: "#fff", overflowY: "hidden" }}>
			
			<video id="capture_video" style={{ maxHeight: "100%", maxWidth: "100%"}} />

			<button 
				className="btn btn-danger w-50" 
				style={{ position: "absolute", bottom: "70px" }} 
				onClick={() => {
					setShowPhotoCapture(false)
					setEvent(null)
				}}
			>
				Cancelar
			</button>

			<button 
				className="btn btn-primary w-50" 
				style={{ position: "absolute", bottom: "20px" }} 
				onClick={handleCapture}
			>
				Tomar foto
			</button>

		</div>
	);
	
}

const PhotoTaked = ({ setAttendancePhoto, handleTakePhoto, handleRegisterPhoto, event, setEvent, photo }) => {
	return (
		<div className="d-flex justify-content-center align-items-center w-100 h-100" style={{ width: "100%", position: "absolute", zIndex: 9999, top: "0", left: "0", backgroundColor: "#fff", overflowY: "hidden" }}>
			
			<img src={URL.createObjectURL(photo)} alt="Foto" style={{ maxHeight: "100%", maxWidth: "100%"}} />

			<button className="btn btn-danger w-50" style={{ position: "absolute", bottom: "70px" }} onClick={handleTakePhoto}>
				Tomar otra foto
			</button>

			<button className="btn btn-primary w-50" style={{ position: "absolute", bottom: "20px" }} onClick={() => {
				if(event.type === "ATTENDANCE_PHOTO") setAttendancePhoto(event.photo)
				else if(event.type === "REGISTER_PHOTO") handleRegisterPhoto(event.photo)
				setEvent(null)
			}}>
				Continuar
			</button>

		</div>
	);
}

function GeoAttendanceView({ 
	loadingRegister,
	loadingAttendance,
	showPhotoCapture,
	coords,
	user, 
	takeRegisterPhoto,
	takeGeolocationPhoto,
	attendancePhoto,
	event,
	setEvent,
	setAttendancePhoto,
	registerPhoto,
	setShowPhotoCapture,
	handleTakePhoto,
	handleRegisterPhoto,
	handleSubmit,
}) {
	return (
		<>
			{
				showPhotoCapture && <PhotoCapture setEvent={setEvent} handleTakePhoto={handleTakePhoto} event={event} setAttendancePhoto={setAttendancePhoto} handleRegisterPhoto={handleRegisterPhoto} setShowPhotoCapture={setShowPhotoCapture}/>
			}
			{
				event != null && event.photo != null
					? 	<div className="content container-fluid py-4">
							<div className="page-header mt-5 m-0 p-0 w-100">
								<PhotoTaked event={event} handleTakePhoto={handleTakePhoto} setAttendancePhoto={setAttendancePhoto} handleRegisterPhoto={handleRegisterPhoto} setEvent={setEvent} photo={event.photo} /> 
							</div>
						</div>
					: 	<div className="content container-fluid py-5">
							<div className="page-header mt-5 py-2">

								<h5 className="mt-3 mb-5 text-center">Bienvenid@ {user.firstname} {user.lastname}</h5>

								<div className="mt-5"> <RealTimeClock /> </div>

								<div className="d-flex justify-content-center m-0 p-0" style={{ height: "14rem", color: "#04b5fc" }}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
										<path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
										<path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
									</svg>
								</div>

								<div className="d-flex justify-content-center m-0 p-0">
									{
										coords?.latitude && coords?.longitude 
											? 	<div className="d-flex flex-column">
													{/* <div className="h5 text-center"> {coords.latitude}, {coords.longitude} </div> */}
												</div>
											: 	<div className="d-flex flex-column">
													<div className="h5 text-center text-danger"> Ubicación no disponible </div>
												</div>
									}
								</div>

								<div className="d-flex flex-column align-items-center justify-content-center m-0 p-0">

									{
										takeRegisterPhoto && 
										<button
											disabled={loadingRegister || registerPhoto}
											className={`btn btn-lg ${registerPhoto ? "btn-success" : "btn-primary"}`}
											style={{ width: "20rem" }}
											onClick={() => handleTakePhoto("REGISTER_PHOTO")}
										>
											{loadingRegister ? "Cargando..." : "Tomar foto de registro"}
										</button>
									}

									{
										takeGeolocationPhoto && 
										<button 
											disabled={loadingAttendance || attendancePhoto}
											className={`btn btn-lg mt-3 ${attendancePhoto ? "btn-success" : "btn-primary"}`}
											style={{ width: "20rem" }}
											onClick={() => handleTakePhoto("ATTENDANCE_PHOTO")}
										>
											{loadingAttendance ? "Cargando..." : "Tomar foto de asistencia"}
										</button>
									}

									<button 
										className="btn btn-primary btn-lg mt-3" 
										style={{ width: "20rem" }}
										onClick={handleSubmit}
									>
										{loadingAttendance ? "Cargando..." : "Registrar asistencia"}
									</button>

								</div>

							</div>
						</div>
			}
		</>
    );
}

export default GeoAttendanceView;