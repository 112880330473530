import React from 'react';
import { Formik, Form, Field } from 'formik';
import { MapContainer, TileLayer } from "react-leaflet";

function FormView({ 
  formikRef = () => { }, 
  coordinates = {},
  locations = [],
  edit = false, 
  geoZone = {}, 
  onSubmit, 
  error, 
  setError,
  setCoordinates,
  onCancel,
  callGetCoordinates,
  isSelecting,
  setIsSelecting,
  RecenterAutomatically,
  MapSelect
}) {

    return (
        <div className='container mt-3'>
            <Formik
                innerRef={formikRef}
                initialValues={geoZone}
                onSubmit={onSubmit}>
                {({ values, setFieldValue, resetForm }) =>
                    <Form>
                        {/* Key */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="key" class="form-control input__field" placeholder=" " required value={values.key || ''} />
                                    <span class="input__label">
                                        Clave <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Description */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="description" class="form-control input__field" placeholder=" " required value={values.description || ''} />
                                    <span class="input__label">
                                        Descripción <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Abbreviation */}
                        <div class="mb-3">
                            <div class="form-group">
                                <label class="input">
                                    <Field type="text" name="abbreviation" class="form-control input__field" placeholder=" " required value={values.abbreviation || ''} />
                                    <span class="input__label">
                                        Abreviación <span className='text-danger fw-bold'>*</span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        {/* Location */}
                        <div class="mb-3">
                          <div class="form-group">
                            <label class="input">
                              <Field type="text" name="location" class="form-control input__field" placeholder=" " value={values.location || ''} />
                              <span class="input__label">Ubicación</span>
                            </label>
                          </div>
                        </div>

                        {/* Check coordinates */}
                        <div class="mb-3">
                          <div class="form-group">
                            <div class="d-grid gap-2">
                              <button type="button" class="btn btn-info text-white" onClick={() => callGetCoordinates(values.location)}>
                                Buscar dirección
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className='w-100 mb-3' style={{ height: "40vh", position: "relative" }}>
                          <div className='' style={{ position: "absolute", zIndex: "999", top: "10px", right: "10px", width: "300px" }}>
                            {
                              locations?.length > 1 
                                && <select className='form-control' onChange={(e) => {
                                  setCoordinates(locations[e.target.value].geometry.location)
                                  setFieldValue("location", locations[e.target.value].formatted_address)
                                }}>
                                  {
                                    locations.map((location, index) =>
                                      <option value={index}>{location.formatted_address}</option>
                                    )
                                  }
                                </select>
                            }
                          </div> 
                          <div className='bg-white p-1 px-2 rounded' style={{ position: "absolute", zIndex: "999", bottom: "10px", left: "10px" }}>
                            {(coordinates?.lat??19.432650).toFixed(6)}, {(coordinates?.lng??-99.133268).toFixed(6)}
                          </div>
                          <div className='p-1 px-2 rounded' style={{ position: "absolute", zIndex: "999", bottom: "10px", right: "10px" }}>
                            <button 
                              type="button" 
                              class="btn btn-info text-white" 
                              onClick={() => {
                              setIsSelecting(!isSelecting);
                              setFieldValue('xi', null);
                              setFieldValue('yi', null);
                              setFieldValue('xf', null);
                              setFieldValue('yf', null);
                            }}
                            >
                              {isSelecting ? <i class="bi bi-x"></i> : <i class="bi bi-pin"></i>}
                            </button>
                          </div>
                          <MapContainer 
                            center={[coordinates?.lat??19.432650, coordinates?.lng??-99.133268]} 
                            zoom={17} 
                            scrollWheelZoom={true} 
                            style={{ 
                              height: "100%", 
                              width: "100%", 
                              zIndex: 0, 
                              position: "absolute", 
                              top: "0", 
                              left: "0", 
                             }}
                          >
                            <TileLayer
                              // url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key="
                              // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key="
                              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <RecenterAutomatically lat={values?.xi??coordinates?.lat??19.432650} lng={values?.yi??coordinates?.lng??-99.133268}/>
                            <MapSelect 
                              name="location"
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </MapContainer>
                        </div>

                        {error ? (
                            <div class="alert alert-danger alert-dismissible fade show d-flex justify-content-between" role="alert">
                                <p className='text-justify' style={{ width: "90%" }}>
                                    {error}
                                </p>
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setError(null)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div class="pt-3">
                            <button type="submit" class="btn btn-primary">
                                <i class="bi bi-plus" style={{ marginRight: "10px" }}></i>
                                {edit ? "Editar geocerca" : "Guardar geocerca"}
                            </button>

                            <button type="button" class="btn btn-danger" style={{ marginLeft: "20px" }} onClick={onCancel.bind(null, resetForm)}>
                                <i class="bi bi-x" style={{ marginRight: "10px" }}></i>
                                Cancelar
                            </button>
                        </div>

                    </Form>
                }</Formik>

        </div >
    )
}

export default FormView;