import React from "react";
import Modal from "helpers/Modal";
import ScheduleZoom from "components/landing/ScheduleZoom";
import ContactForm from "components/landing/ContactForm";
import Resource from "components/landing/Resource";
import Marquee from "react-fast-marquee";
import moment from "moment";

function LandingView({
	selectedResource,
	addModal,
	addContactModal,
	addResourcetModal,
	isVisible,
	setIsVisible,
	isVisibleContact,
	isVisibleResource,
	setIsVisibleContact,
	setIsVisibleResource
}) {
	return (
		<>

			<header id="header" class="header fixed-top d-flex align-items-center" style={{ zIndex: 100 }}>
				<div class="container-fluid d-flex align-items-center justify-content-between" style={{ padding: "0 5rem" }}>

					<a href="/" class="logo me-auto me-xl-0" >
						<img id="logo" src="assets/img/controlhr.png" alt="CRH Logo" style={{ width: "100%" }} />
					</a>

					<a
						class="btn" href="#contactContainer"
						style={{ backgroundColor: "#1aa9d0", color: "white", borderRadius: "10px", border: "0px solid black" }}
					>
						Quiero cotizar
					</a>

				</div>
			</header>

			<section id="hero" class="hero">
				<div class="container-fluid">
					<div class="row">
						<div class="col-xl-6">

							<h1 style={{ fontSize: "2.8rem", color: "white" }}>
								Dí hola a <span style={{ color: "#1aa9d0" }} >Control</span> HR,
								tu nuevo aliado en la <span style={{ color: "#1aa9d0" }} >Gestión</span>
								{" "}de incidencias y asistencias
							</h1>

							<p className="mt-3" style={{ fontSize: "1.5rem", color: "white" }}>
								Nuestro software está diseñado para cubrir las
								necesidades de Recursos Humanos de empresas de
								todos los giros y tamaños
							</p>

							<img src="assets/img/control_zk.png" style={{ width: "100%", position: "unset" }} alt="" id="responsive-facial-img" />

							<div className="d-flex justify-content-center" id="contactContainer">
								<div class="card" id="contactForm">
									<div class="card-body">
										<h3 class="text-center">Cotiza con nosotros</h3>

										<form id="formContact">
											<div class="form-group">
												<div class="row">
													<div class="col-lg-6 col-12 mb-3 mb-lg-0">
														<label class="input">
															<input
																type="text" name="firstname"
																id="firstname"
																className="form-control input__field"
																tabindex="1"
																placeholder=" "
																autofocus="1"
																required
															/>
															<span class="input__label">
																Nombre <span className='text-danger fw-bold'>*</span>
															</span>
														</label>
													</div>
													<div class="col-lg-6 col-12">
														<label class="input">
															<input
																type="text"
																name="lastname"
																className="form-control input__field"
																tabindex="1"
																placeholder=" "
																autofocus="1"
																id="lastname"
															/>
															<span class="input__label">
																Apellidos
															</span>
														</label>
													</div>
												</div>
											</div>
											<div class="form-group my-3">
												<label class="input">
													<input
														type="email"
														name="email"
														className="form-control input__field"
														tabindex="1"
														placeholder=" "
														required
														autofocus="1"
													/>
													<span class="input__label">
														Correo electrónico <span className='text-danger fw-bold'>*</span>
													</span>
												</label>
											</div>
											<div class="form-group my-3">

												<label class="input">
													<input
														type="tel"
														name="phone"
														pattern="[0-9]{10}"
														className="form-control input__field"
														tabindex="1"
														placeholder=" "
														autofocus="1"
													/>
													<span class="input__label">
														Teléfono
													</span>
												</label>
											</div>
											<div class="form-group my-3">

												<label class="input">
													<input
														type="number"
														name="employees"
														className="form-control input__field"
														tabindex="1"
														placeholder=" "
														autofocus="1"
														min="1" max="999999999"
													/>
													<span class="input__label">
														Número de empleados
													</span>
												</label>
											</div>
											<div class="form-group my-3">
												<label class="input">
													<textarea
														name="comments"
														rows="5"
														className="form-control input__field"
														tabindex="1"
														placeholder=" "
														autofocus="1"
													/>
													<span class="input__label">
														Comentarios
													</span>
												</label>
											</div>
											<div class="form-check my-3">
												<input class="form-check-input" type="checkbox" value="" id="notificationsCheck" name="notifications" />
												<label class="form-check-label" for="notificationsCheck">
													Acepto recibir boletines e información de control
												</label>
											</div>
											<div class="d-flex justify-content-center">
												<button
													class="btn"
													type="submit"
													onClick={() => {
														window.gtag("event", "click", { event_category: "landing", event_label: "Quiero Cotizar" });
														window.gtag('event', 'conversion', {
															'send_to': 'AW-11230280690/b6T-CImo-PwYEPL3gesp',
															'event_callback': () => {}
														});
													}}
													style={{ backgroundColor: "#1aa9d0", borderRadius: "20px", color: "white", padding: "0.8rem 1rem" }}
												>
													<b>Quiero cotizar</b>
												</button>
											</div>
										</form>

										<div id="result">
										</div>

									</div>
								</div>
							</div>

						</div>

						<div class="col-xl-6 mt-5" id="facial-img">
							<img src="assets/img/control_zk.png" style={{ width: "100%", position: "unset" }} alt="" />
						</div>

					</div>

					<a href="https://wa.me/+525631875356?text=¡Estoy+interesado!"
						class="whatsapp-icon d-flex align-items-center" target="_blank" rel="noreferrer">
						<i class="bi bi-whatsapp"></i> <h4 className="ms-2">Contáctanos ahora</h4>
					</a>

				</div>
			</section>

			<section className="mt-5" id="clients">

				<div className="container">
					<h1 className="text-center">
						Más de <span style={{ color: "#1aa9d0" }}>1,000</span> empresas ya usan <span style={{ color: "#1aa9d0" }}>Control HR</span> <br /> 
						¿Qué esperas para probarlo?
					</h1>
				</div>

				<Marquee>
					{
						[
							{
								alt: "Seegene client",
								src: "/assets/img/clients/seegene.png"
							},
							{
								alt: "Cruz roja mexicana client",
								src: "/assets/img/clients/cruz_roja_mexicana.png"
							},
							{
								alt: "Nutrisa client",
								src: "/assets/img/clients/nutrisa.png"
							},
							{
								alt: "MoneyCat client",
								src: "/assets/img/clients/money_cat.svg"
							},
							{
								alt: "Truca Courier client",
								src: "/assets/img/clients/truca.png"
							},
							{
								alt: "Comex client",
								src: "/assets/img/clients/comex.png"
							},
							{
								alt: "UPL Client",	
								src: "/assets/img/clients/upl.png"
							},
							{
								alt: "Mobilesco client",
								src: "/assets/img/clients/mobilesco.png"
							},
							{
								alt: "Calmecac client",
								src: "/assets/img/clients/calmecac.png"
							},
							{
								alt: "Ez learning client",
								src: "/assets/img/clients/ez_learning.png"
							},
							{
								alt: "Shewin williams client",
								src: "/assets/img/clients/shewin_williams.png"
							},
							{
								alt: "Pinturas Mark client",
								src: "/assets/img/clients/pinturas_mark.png"
							},
							{
								alt: "Multi Duc client",
								src: "/assets/img/clients/multiduc.png"
							},
							{
								alt: "Grupo Pintavf client",
								src: "/assets/img/clients/grupo_pintavf.png"
							},
							{
								alt: "Tractologistica Dam client",
								src: "/assets/img/clients/tractologistica_dam.png"
							},
							{
								alt: "Lijafayam client",
								src: "/assets/img/clients/lijafayam.jpg"
							}
						].map((client, index) => (
								<img src={client.src} alt={client.alt} style={{ width: "15rem", maxHeight: "150px", margin: "0 1rem" }} />
						))
					}
				</Marquee>

			</section>

			<section id="resources" className="w-100">
				<div class="container mt-5">

					<div class="d-flex justify-content-center text-center">
						<span style={{ fontSize: "3rem", fontWeight: "600", color: "#081b21" }}>
							¡Conoce todas <span style={{ color: "#1aa9d0" }}>nuestras soluciones!</span>
						</span>
					</div>

					<div id="resources" className="mt-3" style={{ marginBottom: "5rem" }}>
						<div className="row justify-content-center" style={{ marginBottom: "5rem" }}>

							<div className="col-md-4 col-lg-2 d-flex justify-content-center my-3">
								<div class="card resource-card" onClick={() => addResourcetModal("ATTENDANCE")}>
									<div class="card-body p-0">
										<div className="d-flex justify-content-center">
											<img src="assets/img/resources/attendance.svg" alt="icon" />
										</div>
										<h5 className="text-center mt-2">Control de asistencia y acceso</h5>
									</div>
								</div>
							</div>

							<div className="col-md-4 col-lg-2 d-flex justify-content-center my-3">
								<div class="card resource-card" onClick={() => addResourcetModal("SCHEDULES")}>
									<div class="card-body p-0">
										<div className="d-flex justify-content-center">
											<img src="assets/img/resources/schedule.svg" alt="icon" />
										</div>
										<h5 className="text-center mt-2">Control de horario de trabajadores</h5>
									</div>
								</div>
							</div>

							<div className="col-md-4 col-lg-2 d-flex justify-content-center my-3">
								<div class="card resource-card" onClick={() => addResourcetModal("HOLIDAYS")}>
									<div class="card-body p-0">
										<div className="d-flex justify-content-center">
											<img src="assets/img/resources/holidays.svg" alt="icon" />
										</div>
										<h5 className="text-center mt-2">Control de vacaciones y ausencias</h5>
									</div>
								</div>
							</div>

							<div className="col-md-4 col-lg-2 d-flex justify-content-center my-3">
								<div class="card resource-card" onClick={() => addResourcetModal("SHIFTS")}>
									<div class="card-body p-0">
										<div className="d-flex justify-content-center">
											<img src="assets/img/resources/shifts.svg" alt="icon" />
										</div>
										<h5 className="text-center mt-2">Gestión de turnos</h5>
									</div>
								</div>
							</div>

							<div className="col-md-4 col-lg-2 d-flex justify-content-center my-3">
								<div class="card resource-card" onClick={() => addResourcetModal("EXTRA_TIME")}>
									<div class="card-body p-0">
										<div className="d-flex justify-content-center">
											<img src="assets/img/resources/extra_time.svg" alt="icon" />
										</div>
										<h5 className="text-center mt-2">Gestión de horas extras</h5>
									</div>
								</div>
							</div>

						</div>
					</div>

				</div>
			</section>

			<section id="about">
				<div class="container">

					<div class="row" style={{ margin: "15rem 0" }}>
						<div className="col-md-6">
							<img className="w-100 h-100" src="assets/img/img_geolocation.png" alt="Geolocation" />
						</div>
						<div className="col-md-1"></div>
						<div className="col-md-5 mt-5">
							<span style={{ fontSize: "2.5rem", fontWeight: "600", color: "white" }}>
								Contamos con la <span style={{ color: "#1aa9d0" }}>mejor</span> tecnología
								biométrica y <span style={{ color: "#1aa9d0" }}>geolocalización</span> con Inteligencia Artificial
							</span>
						</div>
					</div>

					<div class="row" style={{ margin: "15rem 0" }}>
						<div class="col-lg-12">

							<div id="title-horizontal">
								<div class="d-flex justify-content-end">
									<img class="mt-4 inner1" src="assets/img/rais.png" style={{ width: "5rem", height: "5rem" }} />
									<span style={{ fontSize: "3rem", fontWeight: "600", color: "white" }}>
										Conoce nuestro intuitivo
									</span>
								</div>
								<div class="d-flex justify-content-end">
									<span style={{ fontSize: "3rem", fontWeight: "600", color: "white" }}>
										e integral <span style={{ color: "#1aa9d0" }}>dashboard</span>
									</span>
									<img class="mt-4 inner2" src="assets/img/flash.png"
										style={{ height: "5rem", marginTop: "-0.5rem !important", marginleft: "1rem" }} />
								</div>
							</div>

							<div id="title-vertical">
								<div class="d-flex justify-content-center">
									<img class="mt-4 inner1" src="assets/img/rais.png" style={{ width: "5rem", height: "5rem" }} />
									<span style={{ fontSize: "3rem", fontWeight: "600", color: "white" }}>
										Conoce nuestro intuitivo e integral
									</span>
								</div>
								<div class="d-flex justify-content-center">
									<span style={{ fontSize: "3rem", fontWeight: "600", color: "white" }}>
										<span style={{ color: "#1aa9d0" }}>dashboard</span>
									</span>
									<img class="mt-4 inner2" src="assets/img/flash.png"
										style={{ height: "5rem", marginTop: "-0.5rem !important", marginleft: "1rem" }} />
								</div>
							</div>

							<div class="d-flex justify-content-center mt-5">
								<img class="mt-5" src="assets/img/main_view.png" style={{ width: "100%" }} id="main_view" />
								<img class="mt-5" src="assets/img/main_view_vertical.png" style={{ width: "100%" }} id="main_view_vertical" />
							</div>

						</div>
					</div>

				</div>
			</section>

			<section class="mb-5" id="integrations">
				<h3 class="text-center p-4" style={{ backgroundColor: "#1aa9d0", color: "white" }}>
					Control HR se integra con todos los ERP's y sistemas de nómina del mercado
				</h3>
				<div class="container p-5">
					<div class="row">
						<div class="col-md-4 d-flex justify-content-center">
							<img src="assets/img/integrated_systems/meta4.png" style={{ height: "6rem" }} alt="" />
						</div>
						<div class="col-md-3 d-flex justify-content-center">
							<img src="assets/img/integrated_systems/sap.png" style={{ height: "6rem" }} alt="" />
						</div>
						<div class="col-md-5 d-flex justify-content-center">
							<img src="assets/img/integrated_systems/contpaqi.png" style={{ height: "6rem" }} alt="" />
						</div>
					</div>
					<div class="row mt-5">
						<div class="col-md-4 d-flex justify-content-center">
							<img src="assets/img/integrated_systems/contpaqi_nominas.png" style={{ height: "6rem" }} alt="" />
						</div>
						<div class="col-md-3 d-flex justify-content-center">
							<img src="assets/img/integrated_systems/control.png" style={{ height: "6rem" }} alt="" />
						</div>
						<div class="col-md-5 d-flex justify-content-center">
							<img src="assets/img/integrated_systems/aspel_noi.svg" style={{ height: "6rem" }} alt="" />
						</div>
					</div>
				</div>
			</section>

			<section class="" id="benefits">
				<div className="container">
					<div className="d-flex justify-content-center title" >
						<div>
							<h1 class="text-center p-4 text-white" style={{ fontWeight: "bold", fontSize: "3.5rem" }}>¿POR QUÉ ELEGIR CONTROL HR?</h1>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-md-4">
							<div className="card h-100" style={{ padding: "0 2rem 2rem 2rem", backgroundColor: "#cff3ff" }}>
								<div className="d-flex justify-content-center mt-3">
									<img src="/assets/img/benefits/24_hours.svg" alt="24 hours service" width="60%" />
								</div>
								<h3 className="text-center mt-3" style={{ color: "#016fcf" }}>Disponibilidad</h3>
								<p className="text-center">
									Control HR está alojado en la nube, lo cual te permite consultar toda tu información
									desde cualquier lugar, sin la necesidad de instalar ningún programa.
								</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card h-100" style={{ padding: "0 2rem 2rem 2rem", backgroundColor: "#cff3ff" }}>
								<div className="d-flex justify-content-center mt-5">
									<img src="/assets/img/benefits/security.svg" alt="Security service" width="50%" />
								</div>
								<h3 className="text-center mt-3" style={{ color: "#f60" }}>Seguridad</h3>
								<p className="text-center">
									Control HR ofrece máxima seguridad con encriptación avanzada y certificado
									HTTPS, protegiendo datos sensibles y garantizando conexiones seguras para
									una gestión confiable de asistencia.
								</p>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card h-100" style={{ padding: "0 2rem 2rem 2rem", backgroundColor: "#cff3ff" }}>
								<div className="d-flex justify-content-center mt-5">
									<img src="/assets/img/benefits/support.svg" alt="Security service" width="60%" />
								</div>
								<h3 className="text-center mt-4" style={{ color: "#3ab254" }}>Soporte técnico y funcional</h3>
								<p className="text-center">
									Control HR brinda soporte técnico y funcional experto, asegurando implementaciones sin
									problemas, resolución ágil de problemas y maximización de la eficiencia en la gestión
									de asistencia.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<footer className="pt-5">
				<div className="d-flex justify-content-center" id="social_media">
					<a href="https://www.facebook.com/people/Control-HR/100094159298340/" target="_blank" rel="noreferrer">
						<i class="bi bi-facebook"></i>
					</a>
					<a href="https://www.instagram.com/controlhr/" target="_blank" rel="noreferrer">
						<i class="bi bi-instagram"></i>
					</a>
					<a href="https://www.linkedin.com/company/controlhr" target="_blank" rel="noreferrer">
						<i class="bi bi-linkedin"></i>
					</a>
				</div>
				<div className="d-flex justify-content-center mt-5" id="contacts">
					<a
						style={{ padding: "0 !important" }}
						href="tel:+525585397050"
					>
						<i class="bi bi-phone" style={{ marginRight: "1rem" }}></i> Llamada
					</a>
					<a>
						<div class="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => addModal()}>
							<i class="bi bi-camera-video" style={{ marginRight: "1rem" }}></i> Videollamada
						</div>
					</a>
					<a
						className="" style={{ padding: "0 !important" }}
						href="mailto:ventas@controlhr.com.mx?subject = Cotizacion&body = ¡Estoy interesado!"
					>
						<i class="bi bi-envelope" style={{ marginRight: "1rem" }}></i> Mensaje
						{/* <i class="bi bi-envelope" style={{ marginRight: "1rem" }}></i> Mensajería */}
					</a>
				</div>
				<div class="d-flex justify-content-center mt-5 text-center" id="terms">
					<span style={{ marginRight: "1.5rem" }}>@ {moment().year()} CHR Technologies S. de R.L. de C.V.</span>
					<a href="/terms" style={{ textDecoration: "none" }}><span>Privacidad & Legal</span></a>
				</div>
			</footer>


			{/* <section id="contact" class="mt-5" style={{ backgroundColor: "#1aa9d0" }}>

				<div class="container my-5" style={{ backgroundColor: "rgb(255, 255, 255, 0.4)", borderRadius: "10px" }}>
					<div class="row my-4">

						<div class="col-md-6 mb-5">
							<h1 class="text-center my-5">¡Contáctanos ahora!</h1>

							<div class="d-flex justify-content-center" id="text_contact" style={{ padding: "0 3rem" }}>
								<div class="text-justify">
									<p class="mb-5" style={{ fontSize: "1.2rem", textAlign: "justify", textJustify: "inter-word" }}>
										¡Solicita ahora una cotización o un demo gratuito y déjanos mostrarte todas las
										funcionalidades que tenemos para ofrecerte!
									</p>
									<p class="mb-5" style={{ fontSize: "1.2rem", textAlign: "justify", textJustify: "inter-word" }}>
										Completa el formulario a continuación y uno de nuestros expertos estará encantado de
										contactarte para proporcionarte una solución personalizada que se adapte a tu negocio
									</p>
									<p class="mb-5" style={{ fontSize: "1.2rem", textAlign: "justify", textJustify: "inter-word" }}>
										No pierdas más tiempo, ¡comienza a optimizar tus procesos hoy mismo!
									</p>
								</div>
							</div>

						</div>

					</div>

					<hr style={{ border: "3px solid white" }} />

					<div id="contactWays" class="row" style={{ fontSize: "1.5rem" }}>
						<div class="col-md-3 my-5">
							<div class="d-flex justify-content-center">
								<a
									href="tel:+525585397050"
									style={{ textDecoration: "none", color: "black" }}
									onClick={() => {
										window.gtag("event", "click", {
											event_category: "landing",
											event_label: "Llamada",
										})
									}}
								>
									<i class="bi bi-phone" style={{ marginRight: "1rem" }}></i> Llamada
								</a>
							</div>
						</div>
						<div class="col-md-3 my-5">
							<div class="d-flex justify-content-center">
								<a
									href="https://wa.me/+525631875356?text=¡Estoy+interesado!"
									rel="noreferrer"
									style={{ textDecoration: "none", color: "black" }}
									target="_blank"
									onClick={() => {
										window.gtag("event", "click", {
											event_category: "landing",
											event_label: "Whatsapp",
										})
									}}
								>
									<i class="bi bi-whatsapp" style={{ marginRight: "1rem" }}></i> Whatsapp
								</a>

							</div>
						</div>
						<div class="col-md-3 my-5">
							<div class="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => {
								addModal()
								window.gtag("event", "click", {
									event_category: "landing",
									event_label: "Videollamada",
								})
							}}>
								<i class="bi bi-camera-video" style={{ marginRight: "1rem" }}></i> Videollamada
							</div>
						</div>
						<div class="col-md-3 my-5">
							<div class="d-flex justify-content-center">
								<a
									href="mailto:ventas@controlhr.com.mx?subject = Cotizacion&body = ¡Estoy interesado!"
									style={{ textDecoration: "none", color: "black" }}
									target="_blank"
									onClick={() => {
										window.gtag("event", "click", {
											event_category: "landing",
											event_label: "Mensajería",
										})
									}}
								>
									<i class="bi bi-envelope" style={{ marginRight: "1rem" }}></i> Mensajería
								</a>
							</div>
						</div>
					</div>

				</div>

				<div class="container mt-5">
					<div class="d-flex justify-content-between" id="footer_links" style={{ fontWeight: "bold", fontSize: "1rem" }}>
						<div>
							<span style={{ marginRight: "1.5rem" }}>@ 2023 CHR Technologies S. de R.L. de C.V.</span>
							<a href="/terms" style={{ textDecoration: "none", color: "black" }}><span>Privacidad & Legal</span></a>
						</div>
						<div id="social">
							<span style={{ marginRight: "1.5rem" }}>
								<a href="https://www.facebook.com/people/Control-HR/100094159298340/" target="_blank"
									style={{ textDecoration: "none", color: "black" }} rel="noreferrer">
									Facebook
								</a>
							</span>
							<span style={{ marginRight: "1.5rem" }}>
								<a href="https://www.instagram.com/controlhr/" target="_blank"
									style={{ textDecoration: "none", color: "black" }} rel="noreferrer">
									Instagram
								</a>
							</span>
							<span style={{ marginRight: "1.5rem" }}>
								<a href="https://www.linkedin.com/company/controlhr" target="_blank"
									style={{ textDecoration: "none", color: "black" }} rel="noreferrer">
									Linkedin
								</a>
							</span>
						</div>
					</div>
				</div>


			</section > */}

			<a href="#" id="scroll-top" style={{ border: "1px solid gray", backgroundColor: "white" }}
				class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"
					style={{ color: "black", fontWeight: "bold" }}></i></a>

			<div id="overlay">
				<img src="assets/img/controlhr.png" alt="Logo" id="logo" />
			</div>

			<Modal
				title={"Agendar una cita por zoom"}
				isVisible={isVisible}
				setIsVisible={setIsVisible}
			>
				<ScheduleZoom setIsVisible={setIsVisible} />
			</Modal>

			<Modal
				title={""}
				isVisible={isVisibleContact}
				setIsVisible={setIsVisibleContact}
			>
				<ContactForm setIsVisible={setIsVisibleContact} />
			</Modal>

			<Modal
				title={""}
				isVisible={isVisibleResource}
				setIsVisible={setIsVisibleResource}
			>
				<Resource resource={selectedResource} setIsVisible={setIsVisibleResource} />
			</Modal>

		</>
	);
}

export default LandingView;