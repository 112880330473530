import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useDelete, useGetCall, fetchPost } from "utils/api";
import Loading from "helpers/Loading";
import { showResponseMessage } from "utils/message";
import { LoadingContext } from "helpers/LoadingContext";
import View from "components/crh/geoZones/List.view";

function List({
	addEditModal,
	refresh,
	search
}) {

	const { loading, setLoading } = useContext(LoadingContext);

	const [geoZones, setGeozones] = useState([]);
	const [refreshValue, setRefreshValue] = useState(null);
	const [refreshDelete, setRefreshDelete] = useState(null);

	if (refresh !== refreshValue) {
		setRefreshValue(refresh);
	}

	const [callBosses, reqBosses] = useGetCall("/geo_zones", {
		onCompleted: (response) => {
			setLoading(false);
			setGeozones(response.data)
		},
		onError: (error) => {
			setLoading(false);
			showResponseMessage(error.status, "geo_zones", "geocercas", "read")
		}
	})

	const [callDelete, reqDelete] = useDelete("/geo_zones", {
		onCompleted: () => {
			if (!toast.isActive("geozone-deleted"))
				toast.success("Geocerca eliminado", { toastId: "geozone-deleted" });

			setRefreshDelete(Math.random());
		},
		onError: (error) => {
			console.error(error)
			showResponseMessage(error.status, "geozone", "geocercas", "delete")
		}
	});

	useEffect(() => {
		setLoading(true);
		callBosses({ search });
	}, [refreshValue, refreshDelete, search]);

	const onDelete = (id) => {
		fetchPost("/geo_zones/check_permission", { type: "delete" }).then((response) => {
			if (response.data) {
				const data = response.data;
				if (data.isAllowed === true) {
					if (window.confirm("¿Estás seguro de eliminar la geocerca?")) {
						callDelete({ id: id });
					}
				} else {
					if (!toast.isActive("toast-delete-geozone-unauthorized")) {
						toast.error("Error, no tienes los permisos para eliminar geocercas", { toastId: "toast-delete-geozone-unauthorized" })
					}
				}
			}
		})
	}

	const onEdit = (id) => {
		addEditModal(id);
	}

	if (loading) return <Loading />

	return (
		<View 
      geoZones={geoZones} 
      onDelete={onDelete} 
      onEdit={onEdit} 
    />
	);
}

export default List;